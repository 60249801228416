.swiper-slide{
    width:100% !important;
    height:550px !important;
    background-color:rgba(0, 0, 0, 0.6) !important;
    background-blend-mode:darken !important;
}
.slider-image{
    height:inherit !important;
    margin:auto !important;
}

.swiper-pagination-bullet{
    height:15px !important;
    width:15px !important;
    background-color:#0C81DE !important;
}
