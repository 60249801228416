:root{
    --primary-color:#0570C1;
}

.container{
    width:80% !important;
    margin:auto !important;
}

.dropdown-menu{
    border:none !important;
    background-color:#eee !important;
    position:fixed !important;
    width:100%;
    top:38px !important;
    border-radius:0 !important;
}

.raper{
    display:flex;
    justify-content:center;
    align-items:center;
}
.nav-link{
    padding:0px 16px !important;
}
.nav-item::before{
    content: "|" !important; 
    position:absolute;
    top:50%;
    transform:translate(-50% ,-50%);
}
.nav-item:first-child::before{
    content:none;
    display:none;
}


.dropdown-toggle::after {
    display:none !important;
}


.main-color{
    color:var(--primary-color);
}

.wel-images{ 
    width:100%;
        height:400px;
}



@media screen and (max-width:768px){
    .nav-item::before{
        display:none !important;
    }
    .raper{
        flex-direction:column !important;
        justify-content:flex-start;
        align-items:flex-start;
    }

    .navbar-nav{
        position:fixed;
        margin:auto;
        z-index:99;
        background-color:#ddd;
        width:100%;
        left:0;
        display:flex !important;
        justify-content:space-around;
        height:40vh;
    }
    .dropdown-menu{
     
        position:static !important;
     
    }
}

.bg-lightgray{
    background-color:#D3D3D3 !important
}


.scale{
    transform:scaleX(-1);
}